<template>
	<div class="ellipsis-spinner">
		<div class="dot" />
		<div class="dot" />
		<div class="dot" />
	</div>
</template>
<script>
export default {
	name: 'EllipsisSpinner',
};
</script>
<style scoped>
	.ellipsis-spinner, .ellipsis-spinner * {
		box-sizing: border-box;
	}

	.ellipsis-spinner {
		height: 7px;
		width: calc(14px * 3);
	}

	.ellipsis-spinner .dot {
		width: 7px;
		height: 7px;
		margin: 0 calc(7px / 2);
		border-radius: 50%;
		float: left;
		animation: ellipsis-spinner-animation 1000ms steps(1) infinite 0ms;
	}

	.ellipsis-spinner .dot:nth-child(1) {
		animation-delay: calc(300ms * 1);
	}

	.ellipsis-spinner .dot:nth-child(2) {
		animation-delay: calc(300ms * 2);
	}

	.ellipsis-spinner .dot:nth-child(3) {
		animation-delay: calc(300ms * 3);

	}

	@keyframes ellipsis-spinner-animation {
		0% {
			background: #2f353a;
		}
		33% {
			background: #ffffff;
		}
		66% {
			background: #ffffff;
		}
	}
</style>
