import { GET_CRYPTO_PAYMENT_DETAILS } from '@/config/endpoint';
import Req from './AxiosRequest';

class CryptoPayments {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
		this.loading = this.data.loading;
	}

	clear() {
		this.data.clear();
	}

	getCryptoPaymentsDetails(walletAddress) {
		const { method, endpoint } = GET_CRYPTO_PAYMENT_DETAILS;
		return this.data[method](endpoint(walletAddress)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default CryptoPayments;
