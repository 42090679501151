export const BLOCKCHAIN_EXPLORER = {
	name: 'BlockCypher',
	url: 'https://live.blockcypher.com',
};

export const BLOCKCHAIN_PAGES = {
	address: (cryptocurrency, address) => `${BLOCKCHAIN_EXPLORER.url}/${cryptocurrency}/address/${address}/`,
	transaction: (cryptocurrency, transactionId) => `${BLOCKCHAIN_EXPLORER.url}/${cryptocurrency}/tx/${transactionId}/`,
};

export default {};
