export const CRYPTOCURRENCIES = {
	btc: {
		protocol: 'Bitcoin',
	},
};

export const CRYPTO_PAYMENT_STATUSES = {
	new: {
		badgeClass: 'badge-light-red',
		title_translation: 'new_title',
		description_translation: 'new_description',
	},
	pending: {
		badgeClass: 'badge-warning',
		title_translation: 'pending_title',
		description_translation: 'pending_description',
	},
	completed: {
		badgeClass: 'badge-green',
		title_translation: 'completed_title',
		description_translation: 'completed_description',
	},
};

export const REFRESH_INTERVAL = 60000; // 1 minute
