<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<template v-if="hasData">
			<div class="row">
				<div class="col">
					<div
						class="alert alert-info payment-instructions"
						v-html="translate('bitcoin_payment_instructions')" />
					<div
						class="alert alert-warning payment-instructions"
						v-html="translate('bitcoin_warning')" />
				</div>
			</div>
			<div class="row">
				<div class="col text-center">
					<h4>
						{{ translate('payment_status') }}
						{{ translate('order_id', { order_id: data.attributes.order_id }) }}
					</h4>
				</div>
			</div>
			<div class="row">
				<div class="col text-center">
					<h3>
						<span
							:class="status.badgeClass"
							class="badge">
							{{ translate(status.title_translation) }}
							<ellipsis-spinner
								v-if="data.attributes.status === 'new'"
								style="margin-bottom: 3px;"
								class="d-inline-block" />
						</span>
					</h3>
				</div>
			</div>
			<div class="row justify-content-center mt-2 mb-3">
				<div class="col text-center">
					{{ translate(status.description_translation) }}
				</div>
			</div>
			<b-overlay
				:show="loading"
				variant="white"
				opacity="0.85"
				blur="5px">
				<hr class="mb-4">
				<div
					:class="hasPayments ? '' : 'justify-content-center'"
					class="row mt-4">
					<div class="col col-md-6">
						<div class="row">
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''"
								class="col">
								<h5>
									{{ translate('payment_details') }}
								</h5>
							</div>
						</div>
						<div class="row justify-content-center">
							<div class="col">
								<div class="card p-0 w-100">
									<div class="card-body pt-0 pb-2 px-0">
										<div class="row justify-content-center no-gutters">
											<div class="col-auto px-3 mt-3 text-center">
												<span class="h4">
													{{ translate('wallet_address', { currency }) }}
												</span>
											</div>
										</div>
										<div class="row justify-content-center no-gutters">
											<div class="col-auto">
												<div
													id="qr"
													class="mx-1"
													style="max-width: 95%" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div class="row justify-content-center">
									<div class="col-auto">
										<h6>
											{{ translate('wallet_address', { currency }) }}
										</h6>
									</div>
								</div>
								<div class="row justify-content-center mb-3">
									<div class="col">
										<div class="input-group w-100">
											<input
												type="text"
												:class="['xs', 'sm'].includes(windowWidth) ? 'text-medium' : ''"
												class="form-control text-center"
												:value="data.attributes.wallet_address"
												readonly>
											<div class="input-group-append">
												<button
													v-b-tooltip.hover
													v-clipboard="data.attributes.wallet_address"
													:title="translate('copy')"
													class="btn btn-outline-dark"
													type="button"
													@click="copy()">
													<i class="fas fa-copy" />
												</button>
											</div>
										</div>
									</div>
								</div>
								<div class="row justify-content-center">
									<div class="col-auto">
										<h6>
											{{ translate('total_amount', { currency }) }}
										</h6>
									</div>
								</div>
								<div class="row justify-content-center mb-3">
									<div class="col">
										<div class="input-group w-100">
											<input
												type="text"
												class="form-control text-center"
												:value="amount"
												readonly>
											<div class="input-group-append">
												<button
													v-b-tooltip.hover
													v-clipboard="data.attributes.amount"
													:title="translate('copy')"
													class="btn btn-outline-dark"
													type="button"
													@click="copy()">
													<i class="fas fa-copy" />
												</button>
											</div>
										</div>
									</div>
								</div>
								<div class="row justify-content-center">
									<div class="col-auto">
										<h6>
											{{ translate('amount_received', { currency }) }}
										</h6>
									</div>
								</div>
								<div
									:class="!!data.attributes.expires_at ? 'mb-3' : 'mb-5'"
									class="row justify-content-center">
									<div class="col">
										<input
											type="text"
											class="form-control w-100 text-center"
											:value="amountReceived"
											readonly>
									</div>
								</div>
								<template
									v-if="data.attributes.status === 'new'">
									<div class="row justify-content-center">
										<div class="col-auto">
											<h6>
												{{ translate('expiration_date') }}
											</h6>
										</div>
									</div>
									<div class="row justify-content-center mb-5">
										<div class="col">
											<input
												type="text"
												class="form-control w-100 text-center "
												:value="$moment(data.attributes.expires_at.date).format(datetimeFormat)"
												readonly>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>
					<div
						v-if="hasPayments"
						class="col col-md-6">
						<div class="row">
							<div class="col">
								<div class="row">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''"
										class="col">
										<h5>
											{{ translate('transactions_detected') }}
										</h5>
									</div>
								</div>
								<div
									class="row mb-3">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''"
										class="col">
										<span>
											{{ translate('transactions_detected_description') }}
										</span>
									</div>
								</div>
								<div
									v-for="payment in data.attributes.payments"
									:key="payment.transaction_id"
									class="row">
									<div class="col">
										<div class="card">
											<div class="card-body">
												<div class="row font-weight-bold no-gutters">
													<div class="col-6">
														{{ translate('transaction_id') }}
													</div>
													<div class="col-6 text-right">
														<a
															:href="transactionPage(payment.currency.toLowerCase(),payment.transaction_id)"
															target="_blank">
															<span class="d-inline-block text-truncate w-100">
																<i class="fas fa-external-link-alt text-small" />
																{{ payment.transaction_id }}
															</span>
														</a>
													</div>
												</div>
												<div class="row no-gutters">
													<div class="col-6">
														{{ translate('detected_at') }}
													</div>
													<div class="col-6 text-right">
														{{ $moment(payment.detected_at.date).format(datetimeFormat) }}
													</div>
												</div>
												<div class="row no-gutters">
													<div class="col-6">
														{{ translate('amount') }}
													</div>
													<div class="col-6 text-right">
														{{ payment.amount }} {{ payment.currency }}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<template #overlay>
					<div class="text-center">
						<i class="fa fa-fw fa-3x fa-spinner fa-pulse" />
					</div>
				</template>
			</b-overlay>
		</template>
		<is-loading
			v-else
			:loading="loading"
			:has-data="hasData"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')" />
		<hr class="mb-4">
		<div class="row justify-content-center mb-3">
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''"
				class="col">
				<b-button
					variant="outline-primary"
					style="width: 200px"
					class="mb-2"
					@click="goToStore">
					<i class="fas fa-sign-out-alt fa-flip-horizontal text-small" />
					{{ translate('back_to_store') }}
				</b-button>
			</div>
		</div>
	</div>
</template>
<script>
import qrcode from 'qrcode-generator';
import EllipsisSpinner from '@/components/EllipsisSpinner';
import IsLoading from '@/components/Loading';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import { BLOCKCHAIN_PAGES } from '@/settings/Blockchain';
import { CRYPTOCURRENCIES, CRYPTO_PAYMENT_STATUSES, REFRESH_INTERVAL } from '@/settings/CryptoPayments';
import { MMMDYHMA_FORMAT } from '@/settings/Dates';
import { CryptoPayments as CryptoPaymentsMessages, Store } from '@/translations';
import CryptoPayments from '@/util/CryptoPayments';
import AgencySite from '@/mixins/AgencySite';

export default {
	name: 'CryptoPaymentDetails',
	messages: [CryptoPaymentsMessages, Store],
	components: {
		EllipsisSpinner,
		IsLoading,
	},
	mixins: [DocumentTitle, WindowSizes, AgencySite],
	data() {
		return {
			alert: new this.$Alert(),
			cryptoPaymentDetails: new CryptoPayments(),
			interval: null,
			datetimeFormat: MMMDYHMA_FORMAT,
		};
	},
	computed: {
		data() {
			try {
				return this.cryptoPaymentDetails.data.response.data.data;
			} catch (error) {
				return {};
			}
		},
		cryptoChargeStatus() {
			try {
				return this.data.attributes.status;
			} catch (error) {
				return '';
			}
		},
		loading() {
			return this.cryptoPaymentDetails.data.loading;
		},
		hasData() {
			return !!Object.keys(this.data).length;
		},
		hasPayments() {
			return !!this.data.attributes.payments.length;
		},
		amount() {
			return this.formatAmount(this.data.attributes.amount);
		},
		amountReceived() {
			return this.formatAmount(this.data.attributes.amount_received);
		},
		status() {
			return CRYPTO_PAYMENT_STATUSES[this.data.attributes.status.toLowerCase()];
		},
		currency() {
			return this.data.attributes.cryptocurrency_code;
		},
	},
	mounted() {
		this.getPaymentData();
		this.interval = setInterval(this.getPaymentData, REFRESH_INTERVAL);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	methods: {
		getPaymentData() {
			this.cryptoPaymentDetails.getCryptoPaymentsDetails(this.$route.params.wallet_address).then(() => {
				let qrValue = '';

				try {
					// using BIP 0021: https://en.bitcoin.it/wiki/BIP_0021
					const { protocol } = CRYPTOCURRENCIES[this.currency.toLowerCase()];
					qrValue = `${protocol.toLowerCase()}:${this.data.attributes.wallet_address}?amount=${this.data.attributes.amount}`;
				} catch (error) {
					qrValue = this.data.attributes.wallet_address;
				}

				this.setQr(qrValue, 'qr');
			});
		},
		setQr(qrValue, elementId) {
			// qrcode-generator API docs: https://www.npmjs.com/package/qrcode-generator
			const typeNumber = 0;
			const errorCorrectionLevel = 'M';
			const cellSize = 5;
			const qr = qrcode(typeNumber, errorCorrectionLevel);
			qr.addData(qrValue);
			qr.make();
			document.getElementById(elementId).innerHTML = qr.createImgTag(cellSize);
		},
		formatAmount(amount) {
			return Number.parseFloat(amount).toFixed(8);
		},
		copy() {
			this.alert.toast('success', this.translate('copied'));
		},
		goToStore() {
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
			};
			this.alert.confirmation(this.translate('are_you_sure'), this.translate('back_to_store_description'), options).then(() => {
				this.$router.push({ name: this.$route.params.storeName ?? this.getRedirectName('Store') });
			}).catch(() => {});
		},
		transactionPage(cryptocurrency, transactionId) {
			return BLOCKCHAIN_PAGES.transaction(cryptocurrency, transactionId);
		},
	},
};
</script>
<style>
	.payment-instructions > p {
		margin-bottom: 0.5rem;
	}
</style>
